import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './component/partials/footer/footer.component';
import {NavbarComponent} from './component/partials/navbar/navbar.component';
import {AdminLayoutComponent} from './component/admin-layout/admin-layout.component';
import {PublicLayoutComponent} from './component/public-layout/public-layout.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
  ],
  exports: [
    AdminLayoutComponent,
    PublicLayoutComponent,
    FooterComponent,
    NavbarComponent,
  ],
  providers: []
})
export class LayoutModule { }
